import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import Container from "../Container";

const StyledFullWidthInfo = styled.div`
  padding: 0 0 40px;

  @media only screen and (min-width: 740px) {
  }

  @media only screen and (min-width: 920px) {
    padding: ${props => (props.topPadding ? "140px" : "60px")} 0 100px 0;
  }
`;

const FullWidthInfo = ({ headline, body, linkTo, linkCopy, topPadding }) => {
  return (
    <Container>
      <StyledFullWidthInfo topPadding={topPadding}>
        <h2>{headline}</h2>
        <p>{body}</p>
        {linkTo && linkCopy && <Link to={linkTo}>{linkCopy}</Link>}
      </StyledFullWidthInfo>
    </Container>
  );
};

FullWidthInfo.defaultProps = {
  headline: "",
  body: "",
  linkTo: null,
  linkCopy: null,
  topPadding: 0,
};

export default FullWidthInfo;
