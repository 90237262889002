import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import GlobalStyle from "../../global-style";
import Nav from "../Nav";
import Footer from "../Footer";

const Layout = ({ children, alwaysLight, showContactForm }) => {
  const [scrollAmount, setScrollAmount] = useState(0);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = e =>
    setScrollAmount(e.srcElement.scrollingElement.scrollTop);

  return (
    <>
      <GlobalStyle />
      <Nav
        scrollAmount={scrollAmount}
        navScrollChangeAmount={80}
        alwaysLight={alwaysLight}
      />
      {children}
      <Footer showContactForm={showContactForm} />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  alwaysLight: PropTypes.bool,
  showContactForm: PropTypes.bool,
};

Layout.defaultProps = {
  alwaysLight: true,
  showContactForm: true,
};

export default Layout;
