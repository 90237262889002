import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Container from "../Container";
import Map from "../Map";
import ContactForm from "../ContactForm";

const StyledFooter = styled.footer`
  min-height: 200px;
  background-color: #2d2d2d;
  ${"" /* background: url("/wp-content/themes/agace-bros/assets/images/footer-bg.png"); */}
  background-position: center center;
  background-size: cover;
  padding: 30px 0;
  font-family: "Helvetica";
  font-weight: 300;
  font-size: 18px;

  .footer-container {
    display: flex;
    flex-direction: column;
  }

  p {
    font-family: "Helvetica";
    font-size: 14px;
    line-height: 22px;
    color: #ccc;
    margin-top: 0;
  }

  h4 {
    margin: 0 0 8px;
    color: #ccc;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  ul li {
    font-size: 14px;
    line-height: 22px;
    color: #ccc;
  }

  ul li a {
    color: #b1b1b1;
    text-decoration: underline;
    font-size: 14px;
    line-height: 22px;
    font-weight: 300;
  }

  ul li a:hover {
    text-decoration: underline;
    color: #b1b1b1;
  }

  .col-1,
  .col-2 {
    margin-bottom: 30px;
  }

  @media only screen and (min-width: 740px) {
    padding: 50px 0;

    .footer-container {
      flex-direction: row;
      flex-wrap: wrap;
    }

    .col-1 {
      flex: 2;

      &__inner {
        max-width: 350px;
        margin: 0;
      }
    }

    .col-2 {
      flex: 1;
      margin-bottom: 0;
    }

    .col-3 {
      flex: 1;
    }
  }

  @media only screen and (min-width: 920px) {
    .col-1 {
      flex: 2;
      margin-bottom: 0;

      &__inner {
        max-width: 450px;
      }
    }

    .col-2 {
      flex: 1;
    }

    .col-3 {
      flex: 1;
    }
  }
`;

const Footer = ({ showContactForm }) => {
  return (
    <>
      {showContactForm && <ContactForm />}
      <Map />
      <StyledFooter>
        <Container>
          <div className="footer-container">
            <div className="col-1">
              <div className="col-1__inner">
                <h4>About Us</h4>
                <p>
                  Based in Princes Risborough we serve High Wycombe, Aylesbury,
                  Gerrards Cross, Beaconsfield, Thame and all surrounding towns
                  and villages. Contact us now for a free estimate.
                </p>
              </div>
            </div>

            <div className="col-2">
              <h4>Location</h4>
              <ul>
                <li>Brindle Lodge,</li>
                <li>Shootacre Lane,</li>
                <li>Princes Risborough,</li>
                <li>HP27 9EH</li>
              </ul>
            </div>

            <div className="col-3">
              <h4>Contact</h4>
              <ul>
                <li>
                  Email:{" "}
                  <a href="mailto:agacebros@hotmail.com">
                    agacebros@hotmail.com
                  </a>
                </li>
                <li>Mobile: 07738 664418</li>
                <li>Home: 01844 347455</li>
              </ul>
            </div>
          </div>
        </Container>
      </StyledFooter>
    </>
  );
};

Footer.propTypes = {
  showContactForm: PropTypes.bool,
};

Footer.defaultProps = {
  showContactForm: true,
};

export default Footer;
