import React from "react";
import styled from "styled-components";

import Container from "../Container";

const StyledIntroCopy = styled.div`
  padding: 100px 0 40px;

  @media only screen and (min-width: 740px) {
  }

  @media only screen and (min-width: 920px) {
    padding: ${props => (props.topPadding ? "180px" : "60px")} 0 100px 0;
  }
`;

const IntroCopy = ({ headline, body, topPadding }) => {
  return (
    <Container>
      <StyledIntroCopy topPadding={topPadding}>
        <h1>{headline}</h1>
        <p>{body}</p>
      </StyledIntroCopy>
    </Container>
  );
};

IntroCopy.defaultProps = {
  headline: "",
  body: "",
  linkTo: null,
  linkCopy: null,
  topPadding: 0,
};

export default IntroCopy;
