import React, { useEffect } from "react";
import mapboxgl from "mapbox-gl";
import styled from "styled-components";

const StyledMap = styled.div`
  height: 350px;
  position: relative;

  @media only screen and (min-width: 740px) {
    height: 480px;
  }

  .map {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
`;

const config = {
  mapboxAccessToken:
    "pk.eyJ1IjoiaWFtc3R1YXJ0IiwiYSI6IjA3MzE1ZmRhMDY1MmFhNWY4ZmE4Y2VkNmUyOTgzYzdlIn0.3h-XzQxt07bsIIgRZKtjtA",
  style: "iamstuart/ck759lhv13v821ipmrd1zwn9b",
};

mapboxgl.accessToken = config.mapboxAccessToken;

const Map = () => {
  let mapContainer;

  useEffect(() => {
    new mapboxgl.Map({
      container: mapContainer,
      style: `mapbox://styles/${config.style}`,
      center: [-0.8460794, 51.7106469],
      zoom: 10,
      scrollZoom: false,
    });
  }, [mapContainer]);

  return (
    <StyledMap>
      <div ref={el => (mapContainer = el)} className="map"></div>
    </StyledMap>
  );
};

export default Map;
