import React from "react";
import styled from "styled-components";

const StyledContainer = styled.div`
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 15px;

  @media only screen and (min-width: 740px) {
  }

  @media only screen and (min-width: 920px) {
    padding: 0 30px;
  }
`;

const Container = ({ children }) => (
  <StyledContainer>{children}</StyledContainer>
);

export default Container;
