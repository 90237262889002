import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    * {
        box-sizing: border-box;
    }

    html, body {
        margin: 0;
        padding: 0;
        font-family: "Helvetica";
    }

    h1 {
        font-size: 30px;
        line-height: 38px;
    }

    h1,
    h2,
    h3,
    h4 {
        color: #424242;
        font-family: "Lato";
    }

    p,
    ul,
    li, label {
        font-size: 16px;
        line-height: 26px;
        font-weight: 100;
        color: #565656;
    }

    a {
        text-decoration: none;
        font-size: 16px;
        line-height: 26px;
        font-weight: 100;
        color: #3ca2e6;
        transition: color 250ms ease-in-out;
    }
    a:hover {
        color: rgba(60, 162, 230, 0.5);
    }
`;
