import React from "react";
import Img from "gatsby-image";
import styled from "styled-components";

const StyledImageGallery = styled.div`
  min-height: 350px;
  background-color: #c1c1c1;
  position: relative;

  .gatsby-image-wrapper,
  img {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }

  @media only screen and (min-width: 740px) {
    min-height: 480px;
  }

  @media only screen and (min-width: 920px) {
    min-height: 600px;
  }
`;

const ImageGallery = ({ images }) => {
  return (
    <StyledImageGallery>
      {images.map(image => (
        <Img fluid={image.childImageSharp.fluid} />
      ))}
    </StyledImageGallery>
  );
};

export default ImageGallery;
