import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import Container from "../Container";

const StyledForm = styled.form`
  padding: 50px 0;

  .title-holder {
    width: 100%;
    display: block;
    text-align: center;

    &.toggle {
      cursor: pointer;
    }
  }

  form {
    height: auto;
    max-height: 0;
    transition: max-height 250ms ease-in-out;
    overflow: hidden;

    &.open {
      max-height: 600px;
    }
  }

  .cols {
    margin-top: 40px;

    @media only screen and (min-width: 740px) {
      margin-top: 60px;
      display: flex;
      justify-content: space-between;
    }
  }

  .col {
    @media only screen and (min-width: 740px) {
      width: 49%;
    }
  }

  input,
  textarea {
    width: 100%;
    min-height: 40px;
    font-size: 16px;
    line-height: 26px;
    font-weight: 100;
    color: #565656;
    border: 1px solid #adadad;
    padding: 10px 15px;
    resize: none;
    margin-top: 8px;
    margin-bottom: 16px;
    font-family: "Helvetica";
  }

  input[type="submit"] {
    background-color: #6b6b6b;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
    width: auto;
    padding: 10px 40px;
  }
`;

const ContactForm = ({ alwaysOpen }) => {
  const [isOpen, setIsOpen] = useState(false);

  const shouldBeOpen = alwaysOpen || isOpen;

  return (
    <Container>
      <StyledForm>
        {alwaysOpen ? (
          <div className="title-holder">
            <h2>Send us an email</h2>
          </div>
        ) : (
          <div
            className="title-holder  toggle"
            onClick={() => setIsOpen(!isOpen)}
          >
            <h2>Send us an email</h2>
          </div>
        )}
        <form
          name="contact"
          method="post"
          action="/success"
          data-netlify-honeypot="bot-field"
          data-netlify="true"
          className={shouldBeOpen ? "open" : null}
        >
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" />
          <div className="cols">
            <div className="col">
              <label>
                Name
                <input type="text" name="name" />
              </label>
            </div>
            <div className="col">
              <label>
                Email or Telephone
                <input type="text" name="details" />
              </label>
            </div>
          </div>
          <label>
            Message<textarea name="message"></textarea>
          </label>
          <input type="submit" value="Send" />
        </form>
      </StyledForm>
    </Container>
  );
};

ContactForm.defaultProps = {
  alwaysOpen: false,
};

ContactForm.propTypes = {
  alwaysOpen: PropTypes.bool,
};

export default ContactForm;
