import PropTypes from "prop-types";
import React, { useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import navLogo from "../../images/nav/agace_black.png";

import Container from "../Container";

const StyledNav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  z-index: 2;
  color: #fff;
  transition: height 250ms ease-in-out, color 250ms ease-in-out,
    background-color 250ms ease-in-out, border-color 250ms ease-in-out;

  @media only screen and (min-width: 740px) {
    height: 80px;
  }

  &.light {
    background-color: #fff;
    color: #000;
    border-color: #e4e4e4;
  }

  .nav__container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;

    @media only screen and (min-width: 740px) {
      height: 80px;
    }
  }

  &.nav-tall {
    height: auto;

    @media only screen and (min-width: 740px) {
      height: 120px;
    }

    .nav__container {
      height: 60px;

      @media only screen and (min-width: 740px) {
        height: 80px;
      }
    }
  }

  .nav__logo {
    a {
      line-height: 0;

      img {
        max-width: 80px;

        @media only screen and (min-width: 740px) {
          max-width: 125px;
        }
      }
    }
  }

  .nav__logo__wrapper {
    opacity: 1;
    transition: opacity 250ms ease-in-out;

    &--hidden {
      opacity: 0;
    }
  }

  .nav__menu-toggle {
    cursor: pointer;
    font-family: "Lato";
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
  }

  .nav__menu-toggle-button {
    display: flex;
    align-items: center;
  }

  .nav__menu-toggle-copy {
    opacity: 1;
    transition: opacity 250ms ease-in-out;
    display: none;

    @media only screen and (min-width: 740px) {
      display: inline-block;
    }

    &--open {
      opacity: 0;
    }
  }

  .nav__menu-toggle-icon {
    width: 14px;
    height: 10px;
    position: relative;
    margin-left: 5px;

    span {
      height: 2px;
      width: 14px;
      background-color: #fff;
      display: block;
      position: absolute;
      top: 4px;
      transition: top 250ms ease-in-out, transform 250ms ease-in-out,
        opacity 250ms ease-in-out, background-color 250ms ease-in-out;

      &:first-child {
        top: 0;
      }

      &:last-child {
        top: 8px;
      }
    }

    &.light span {
      background-color: #000;
    }

    &--open {
      span {
        opacity: 0;

        &:first-child {
          top: 4px;
          opacity: 1;
          transform: rotate(45deg);
        }

        &:last-child {
          top: 4px;
          opacity: 1;
          transform: rotate(-45deg);
        }
      }
    }
  }

  .nav-menu-holder {
    opacity: 0;
    visibility: hidden;
    transition: visibility 250ms ease-in-out, opacity 250ms ease-in-out;
    display: none;

    @media only screen and (min-width: 740px) {
      display: block;
    }

    &.nav-open {
      opacity: 1;
      visibility: visible;
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    text-align: center;
    line-height: 80px;
  }

  ul li {
    display: inline;
    padding: 0 20px;
    letter-spacing: 0.5px;
  }

  ul li a {
    color: #565656;
    text-decoration: none;
    font-size: 15px;
    font-weight: 500;

    &:hover,
    &:focus {
      color: #adadad;
    }
  }

  .mobile-nav {
    display: none;

    @media only screen and (min-width: 740px) {
      display: none;
    }

    ul {
      padding: 0 0 20px;

      li {
        display: block;
        margin: 10px 0;
      }
    }

    &.nav-open {
      display: block;

      @media only screen and (min-width: 740px) {
        display: none;
      }
    }
  }
`;

const Nav = ({ scrollAmount, navScrollChangeAmount, alwaysLight }) => {
  const [navOpen, setNavOpen] = useState(false);

  const scrolledPastChangePoint = scrollAmount >= navScrollChangeAmount;

  const renderNavList = () => (
    <ul>
      <li>
        <Link to="/">Home</Link>
      </li>
      <li>
        <Link to="/services/">Services</Link>
      </li>
      <li>
        <Link to="/portfolio/">Portfolio</Link>
      </li>
      <li>
        <Link to="/testimonials/">Testimonials</Link>
      </li>
      <li>
        <Link to="/contact/">Contact</Link>
      </li>
    </ul>
  );

  return (
    <StyledNav
      className={[
        navOpen ? "nav-tall" : null,
        alwaysLight || scrolledPastChangePoint || navOpen ? "light" : null,
      ]}
    >
      <Container>
        <div className="nav__container">
          <div className="nav__logo">
            <div
              className={`nav__logo__wrapper  ${
                !navOpen && !alwaysLight && !scrolledPastChangePoint
                  ? "nav__logo__wrapper--hidden"
                  : null
              }`}
            >
              <Link to="/">
                <img src={navLogo} alt="Agace Bros logo" />
              </Link>
            </div>
          </div>
          <div
            className={
              navOpen ? "nav-menu-holder  nav-open" : "nav-menu-holder"
            }
          >
            {renderNavList()}
          </div>
          <div
            className="nav__menu-toggle"
            onClick={() => setNavOpen(!navOpen)}
          >
            <div className="nav__menu-toggle-button">
              <span
                className={`nav__menu-toggle-copy  ${
                  navOpen ? "nav__menu-toggle-copy--open" : null
                }`}
              >
                MENU
              </span>
              <div
                className={`nav__menu-toggle-icon  ${
                  navOpen ? "nav__menu-toggle-icon--open" : null
                }  ${
                  alwaysLight || scrolledPastChangePoint || navOpen
                    ? "light"
                    : null
                }`}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
        <div className={navOpen ? "mobile-nav  nav-open" : "mobile-nav"}>
          {renderNavList()}
        </div>
      </Container>
    </StyledNav>
  );
};

Nav.propTypes = {
  siteTitle: PropTypes.string,
  scrollTop: PropTypes.number,
  navScrollChangeAmount: PropTypes.number,
  alwaysLight: PropTypes.bool,
};

Nav.defaultProps = {
  siteTitle: ``,
  scrollAmount: 0,
  navScrollChangeAmount: 80,
  alwaysLight: true,
};

export default Nav;
